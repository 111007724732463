import React, { FC, useContext } from "react";
import { Member } from "../../backendProxies";
import { TranslationContext } from "../../context/TranslationContext";

type Props = {
  member: Member;
  cb: () => void;
};

const MatchButtons: FC<Props> = ({ member, cb }) => {
  const { i } = useContext(TranslationContext);
  const handleDecline = () => {
    member.declineDateMe().then(() => {
      cb();
    });
  };

  const handleLike = () => {
    member.acceptDateMe().then(() => {
      cb();
    });
  };

  return (
    <div className="MatchButtons flex w-full md:w-60 space-x-2 md:px-4 justify-center sm:justify-between bg-transparent">
      <MatchButton
        svg={
          <svg
            width="41"
            height="36"
            viewBox="0 0 41 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-8 sm:w-10"
          >
            <path
              d="M21.9078 35.9302L20.6069 35.0108C18.8944 33.8 3.8814 22.8926 3.8814 12.9285C3.78427 10.1366 4.79634 7.41989 6.69639 5.37205C8.59645 3.32421 11.2299 2.11186 14.0212 2C15.562 2.01514 17.0778 2.39082 18.4473 3.09695C19.8168 3.80309 21.0019 4.82006 21.9078 6.06645C22.8137 4.82006 23.9988 3.80309 25.3683 3.09695C26.7378 2.39082 28.2536 2.01514 29.7943 2C32.5856 2.11186 35.2191 3.32421 37.1192 5.37205C39.0192 7.41989 40.0313 10.1366 39.9342 12.9285C39.9342 22.8926 24.9212 33.8 23.2087 35.0108L21.9078 35.9302ZM14.0212 6.5066C12.4259 6.61964 10.9395 7.35742 9.88476 8.55978C8.83006 9.76213 8.29222 11.332 8.388 12.9285C8.388 18.8111 17.1954 26.7637 21.9078 30.3705C26.6202 26.7653 35.4276 18.8111 35.4276 12.9285C35.5234 11.332 34.9855 9.76213 33.9308 8.55978C32.8761 7.35742 31.3897 6.61964 29.7943 6.5066C28.1989 6.61964 26.7125 7.35742 25.6578 8.55978C24.6032 9.76213 24.0653 11.332 24.1611 12.9285H19.6545C19.7503 11.332 19.2124 9.76213 18.1577 8.55978C17.103 7.35742 15.6166 6.61964 14.0212 6.5066V6.5066Z"
              fill="white"
            />
            <line
              x1="1.23929"
              y1="2.18023"
              x2="39.2393"
              y2="32.1802"
              stroke="white"
              strokeWidth="4"
            />
          </svg>
        }
        text={i("decline")}
        onClick={handleDecline}
      />

      <MatchButton
        svg={
          <svg
            width="37"
            height="35"
            viewBox="0 0 37 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="relative top-1 w-8 sm:w-10"
          >
            <path
              d="M18.9078 34.1802L17.6069 33.2608C15.8944 32.0501 0.881398 21.1426 0.881398 11.1785C0.784266 8.38665 1.79634 5.66989 3.69639 3.62205C5.59645 1.57421 8.22995 0.361863 11.0212 0.25C12.562 0.265142 14.0778 0.640819 15.4473 1.34695C16.8168 2.05309 18.0019 3.07006 18.9078 4.31645C19.8137 3.07006 20.9988 2.05309 22.3683 1.34695C23.7378 0.640819 25.2536 0.265142 26.7943 0.25C29.5856 0.361863 32.2191 1.57421 34.1192 3.62205C36.0192 5.66989 37.0313 8.38665 36.9342 11.1785C36.9342 21.1426 21.9212 32.0501 20.2087 33.2608L18.9078 34.1802ZM11.0212 4.7566C9.42586 4.86964 7.93946 5.60742 6.88476 6.80978C5.83006 8.01213 5.29222 9.58198 5.388 11.1785C5.388 17.0611 14.1954 25.0137 18.9078 28.6205C23.6202 25.0153 32.4276 17.0611 32.4276 11.1785C32.5234 9.58198 31.9855 8.01213 30.9308 6.80978C29.8761 5.60742 28.3897 4.86964 26.7943 4.7566C25.1989 4.86964 23.7125 5.60742 22.6578 6.80978C21.6032 8.01213 21.0653 9.58198 21.1611 11.1785H16.6545C16.7503 9.58198 16.2124 8.01213 15.1577 6.80978C14.103 5.60742 12.6166 4.86964 11.0212 4.7566Z"
              fill="white"
            />
          </svg>
        }
        text={i("like")}
        onClick={handleLike}
      />
    </div>
  );
};

function MatchButton({
  svg,
  text,
  onClick,
}: {
  svg: React.ReactNode;
  text: string;
  onClick: () => void;
}) {
  return (
    <div
      onClick={onClick}
      style={{
        backdropFilter: "blur(3px)",
      }}
      className="h-20 w-20 sm:h-24 sm:w-28 rounded-lg cursor-pointer relative bg-gradient-to-r from-custom-gradient-orange to-custom-red opacity-100 sm:opacity-70 hover:opacity-100"
    >
      <div className="absolute top-3 sm:top-6 left-0 w-full flex justify-center">
        {svg}
      </div>
      <div>
        <p className="text-white absolute bottom-2 sm:bottom-2 left-0 text-center w-full font-light">
          {text}
        </p>
      </div>
    </div>
  );
}

export default MatchButtons;
