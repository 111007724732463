import { FC, useContext } from "react";
import CardMemberProfileBadge from "../cardMemberProfileBadge";

import { Member } from "../../backendProxies";
import { TranslationContext } from "../../context/TranslationContext";

type Props = {
  isOnline: boolean;
  lastOnlineTime: string;
  member: Member;
};

const CardMemberProfileStatus: FC<Props> = ({
  isOnline,
  lastOnlineTime,
  member,
}) => {
  const { i } = useContext(TranslationContext);
  return (
    <div className="CardMemberProfileStatus grow-none w-[13px] ">
      <div className="flex flex-row items-center  relative">
        {isOnline && (
          <div
            data-testid="online-circle"
            className="bg-custom-status-green w-[12px] h-[12px] rounded-full"
          />
        )}
        {!isOnline && lastOnlineTime!="" && (
          <div
            data-testid="online-circle"
            className="bg-custom-orange w-[12px] h-[12px] rounded-full"
          />
        )}
        {
        /*
          <div className="inline text-white text-base font-semibold">
          
            <div>
                <p className="hidden md:flex -top-[2px] relative">
                  {lastOnlineTime}
                </p>
              </div>
            
          
        </div>
        */}
      </div>      
    </div>
  );
};

export default CardMemberProfileStatus;
