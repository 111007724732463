import { FC, useContext, useEffect, useRef, useState, Fragment } from "react";
import { useRouter } from "next/router";
import { Dialog, Transition } from "@headlessui/react";
import CardMemberProfileLocation from "../cardMemberProfileLocation";
import CardMemberProfileName from "../cardMemberProfileName";
import CardMemberProfileStatus from "../cardMemberProfileStatus";
import { DetailedMember, Member } from "../../backendProxies";
import useTranslation, {
  TranslationContext,
} from "../../context/TranslationContext";
import ProfileMailSettingsContainer from "../profileMailSettingsContainer";
import ProfileBlock from "../profileBlock";
import ShareButtons from "../shareButtons";
import ProfileDate from "../profileDate";
import BodyAdContainerFlexible from "../bodyAdContainerFlexible";
import BodyAdContainerMobile from "../bodyAdContainerMobile";
import AdWrapper from "../AdWrapper";
import ProfileInterviewContainer from "../profileInterviewContainer";
import ProfileInterestContainer from "../profileInterestContainer";
import ProfileStatsContainer from "../profileStatsContainer";
import ProfileAboutMeContainer from "../profileAboutMeContainer";
import SugarDaddyAd from "../SugarDaddyAd";
import ProfilePhotosContainer from "../profilePhotosContainer";
import ProfileFullCard from "../profileFullCard";
import useWindowSize from "../../hooks/useWindowSize";
import useUser from "../../hooks/useUser";
import { DOMAIN } from "../../backendAPI/Api";
import Link from "next/link";
import ButtonBGBlue from "../buttonBGBlue";
import NoMatchFound from "../noMatchFound";
import { SettingsLinkRoutes } from "../../pages/settings";
import api from "../../backendAPI";
import useConfirmDialog from "../../hooks/useConfirmDialog";
import useToast from "../../hooks/useToast";
import CardMemberProfileHeader from "../cardMemberProfileHeader";
import CardMemberProfileActionRemove from "../cardMemberProfileActionRemove";
import CardMemberProfileActionsMenu from "../cardMemberProfileActionsMenu";
import MatchButtons from "../matchButtons";
import OpenProfileLink from "../openProfileLink/OpenProfileLink";

type Props = {
  member: Member;
  onRemove?: () => void;
  hasMatchActions?: boolean;
  hasActions?: boolean;
};

const CardMemberProfile: FC<Props> = ({
  member,
  onRemove,
  hasMatchActions = false,
  hasActions = true,
}) => {
  const { lang } = useContext(TranslationContext);
  const router = useRouter();
  const dialogTopRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const selfRef = useRef<HTMLDivElement>(null);
  const BASE_CLASS_NAME =
    "CardMemberProfile xs:max-w-[250px] max-w-[170px] bg-center m-auto md:h-[330px] h-[224px] rounded-2xl overflow-hidden bg-cover relative col-span-1 w-full";

  useEffect(() => {
    if (isDeleted) {
      selfRef.current?.style.setProperty("display", "none");
    }
  }, [isDeleted]);

  if (!member || !member.info) return null;

  if (member.info.type === "promotion") {
    switch (member.info.promotion?.type ?? "blue") {
      case "blue":
        return (
          <div
            style={{ backgroundColor: "blue" }}
            className={
              BASE_CLASS_NAME +
              " bg-gradient-to-r from-custom-gradient-blue to-custom-gradient-purple flex flex-col p-4 justify-center items-center"
            }
            dangerouslySetInnerHTML={{
              __html: member.info.promotion?.html ?? "",
            }}
          ></div>
        );
      case "red":
        return (
          <div
            style={{ backgroundColor: "red" }}
            className={
              BASE_CLASS_NAME +
              " bg-gradient-to-r from-custom-gradient-orange to-custom-red flex flex-col p-4 justify-center items-center"
            }
            dangerouslySetInnerHTML={{
              __html: member.info.promotion?.html ?? "",
            }}
          ></div>
        );
      case "custom":
        const custom = member.info.promotion?.custom;
        if (!custom) return null;

        return (
          <div
            className={
              BASE_CLASS_NAME +
              " bg-custom-orange-100 flex flex-col max-h-full overflow-hidden"
            }
          >
            <div className="flex w-full grow shrink min-h-0">
              <img
                className="object-cover w-full h-full"
                src={custom.image ?? ""}
                alt={custom.title ?? ""}
              />
            </div>
            <div className="flex flex-col justify-center items-center w-full shrink-0 max-h-fit h-fit p-4 gap-2">
              <p
                className="font-semibold text-center"
                dangerouslySetInnerHTML={{
                  __html: custom.title ?? "",
                }}
              ></p>
              <p
                className="text-center"
                dangerouslySetInnerHTML={{
                  __html: custom.description ?? "",
                }}
              ></p>
              <ButtonBGBlue
                className="mt-1"
                innerText={custom.buttonText}
                onClick={async () => {
                  await api.general.post("/promote/searchResult", {
                    category: custom.category,
                    type: "custom",
                  });
                  router.push(custom.url.replace("/app/", "/"));
                }}
              />
            </div>
          </div>
        );
    }
  }

  return (
    <div
      ref={selfRef}
      data-testid={`card-member-profile-for-${member.info.userId}-sex-${member.info.genre}`}
      style={{ backgroundImage: `url(${member.info.mainImage})` }}
      className={BASE_CLASS_NAME}
    >
      <CardMemberProfileHeader photoCount={member.info?.nbPhotos}>
        {/* {hasActions && (
          <div className="w-full pointer-events-none justify-end flex flex-row">
            {onRemove ? (
              <CardMemberProfileActionRemove onClick={handleRemove} />
            ) : (
              <CardMemberProfileActionsMenu member={member} />
            )}
          </div>
        )} */}
      </CardMemberProfileHeader>
      {hasMatchActions && (
        <div className="match-button-container flex w-full justify-center absolute bottom-0 p-2 items-end h-[40%] z-40">
          <MatchButtons member={member} cb={() => setIsDeleted(true)} />
        </div>
      )}
      <OpenProfileLink username={member.info.userName}>
        <div className="flex flex-col justify-end cursor-pointer items-end md:py-2 py-2.5 md:px-3.5 px-2.5 h-full w-full bg-gradient">
          <div className="w-full">
            <div className="flex flex-row justify-between items-center">
              <CardMemberProfileName
                name={member.info.userName}
                age={member.info.age}
              />
              <CardMemberProfileStatus
                member={member}
                isOnline={member.info.isOnline}
                lastOnlineTime={member.info.LastUpdate_text}
              />
            </div>
            <CardMemberProfileLocation
              member={member}
              location={member.info.city}
            />
          </div>
        </div>
      </OpenProfileLink>
    </div>
  );
};

export default CardMemberProfile;
